import React, { useEffect, Fragment, useState } from "react"
import About1 from "../../../../../assets/img/july/overdraft-blur.inline.svg"
import About2 from "../../../../../assets/img/july/how-to-spotlight-blur.inline.svg"
import BorrowImage from "../../../../../assets/img/july/overdraft-spotlight.jpg"
import HowBorrowImage from "../../../../../assets/img/july/how-to-spotlight.jpg"
import Play from "../../../../../assets/img/play.inline.svg"
import PlayPrimary from "../../../../../assets/img/july/play-icon-primary.inline.svg"
import Panel from "./component/panel"
import { Img } from "react-image"
import Card from "../../../../../assets/img/kuda-app-miniIcon.inline.svg"
import Zero from "../../../../../assets/img/hazard-icon.inline.svg"
import SaveIcon from "../../../../../assets/img/pay-icon.inline.svg"
import BelowFoldCards from "../../general/belowFoldCards"
import EntryFold from "../../general/entryFold"
import CTA from "../../general/cta"
import { scrollToElement } from "../../../../utility/utils"

const entryContent = {
  title: "An instant short-term loan when you need it.",
  subtitle: (
    <Fragment>
      <span className="block title-bottom--spacing">
        No paperwork, no embarrassment, just 0.3% daily interest.
      </span>
      <span className="block">
        Kuda Overdrafts are short-term loans you can take anytime as long as you
        use your Kuda account regularly.
      </span>
    </Fragment>
  ),
  illustration: null,
}

const kudaTopFeatures = [
  {
    icon: <Card />,
    text:
      "Sign up on Kuda and upgrade your account with your BVN (Bank Verification Number) and a valid ID.",
  },
  {
    icon: <SaveIcon />,
    text:
      "Use your account regularly - buy airtime, pay bills, make transfers etc.",
  },
  {
    icon: <Zero />,
    text: "We’ll automatically offer you an overdraft!",
  },
]

const Borrow = ({ showModal, setId }) => {
  const [state, setState] = useState({
    activeTab: 0,
  })
  const [localOverdraft, getLocalOverdraft] = useState([])
  const [questions, setQuestions] = useState([])
  let localTerms = []
  if (typeof window !== "undefined") {
    localTerms = localStorage.getItem("kudaOverdraftTerms")
  }

  const activateTab = index => {
    setState(prevState => ({
      ...prevState,
      activeTab: prevState.activeTab === index ? -1 : index,
    }))
  }

  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  const { activeTab } = state

  const getdata = async firebase => {
    const data = await firebase.FireBase()
    setQuestions(firebase.getOverDraftTerms())
    getLocalOverdraft(firebase.getOverDraftTerms())
    return data
  }

  const countries = [
    {
      heading: "OVERDRAFT FACILITY",
      list: (
        <span className="flex flex-column"><span>You have requested Kuda Microfinance Bank Limited (“Kuda”) to provide you with an overdraft facility (the “Overdraft Facility”) on your Kuda account (“Account”). This Overdraft Facility is linked to your Account and will allow you withdraw money in excess of your available balance, up to a stipulated limit. This offer letter, therefore, sets out the key information regarding your request for the Overdraft Facility and the terms and conditions on which we grant you the Overdraft Facility (the “Offer Letter”).
        </span><br />
          <span className="mb-4 flex">This Offer Letter and its terms and conditions will apply, in addition to your Account’s terms and conditions, when you accept this Overdraft Facility. We ask that you please read and consider this document in its entirety before deciding whether to accept the offer to provide you with this Overdraft Facility.</span>
          <span className="mb-4 flex">
            FACILITY TYPE: Overdraft <br />
            TENOR: 90 days <br />
            DISBURSEMENT DATE: The date on which the Facility Amount is credited into the Borrower’s account <br />
            MODE OF DISBURSEMENT: Bank Transfer to Borrower <br />
            MATURITY DATE: 30 days from the Disbursement Date <br />
            INTEREST RATE: 0.3% of the Overdraft Facility Amount accruing on a daily basis until fully repaid.
          </span><br />
        </span>),
      index: 1
    },
    {
      heading: "GENERAL TERMS AND CONDITIONS Advance of Overdraft Facility",
      list: (
        <span className="flex flex-column"><span>Kuda agrees to make available in the Borrower’s Account an amount not exceeding the Overdraft Amount, and the Borrower agrees to take the Overdraft Amount subject to the terms and conditions of this Offer Letter.
        </span><br />
          <span className="mb-4 flex">This offer is conditional upon the provision of the satisfactory credit report and further verification checks on the Borrower; and Kuda reserves the right to withdraw this offer in the event that the results of the credit checks and verification checks are unsatisfactory.</span>
          <span className="mb-4 flex">Cooling Off Period - Provided that you have not drawn down on the Overdraft Facility, you may elect to reject the terms of this Offer Letter in its entirety within a period of three (3) Business Days from the date of execution of this Offer Letter. You will not be charged any fees for this.</span>
        </span>),
      index: 2
    },
    {
      heading: "Repayment and Prepayment",
      list: (
        <span className="flex flex-column"><span>You are required to repay the Overdraft Facility and the Interest on or before the Maturity Date and Kuda is hereby authorised to automatically debit your account on the Maturity Date to settle the Overdraft Facility and any accrued interests (“Outstanding Balance”). Where the Outstanding Balance is not paid in full by the Maturity Date, the Overdraft Facility shall be treated as being in arrears and any credit in your Account shall automatically be applied by Kuda towards the settlement of the Outstanding Balance.
        </span><br />
          <span className="mb-4 flex">You may fully prepay the Overdraft Facility prior to the scheduled Maturity Date by paying to Kuda, the Outstanding Balance, together with other monies due under this Offer Letter up to the date of such prepayment.</span>
          <span className="mb-4 flex">Notwithstanding the forgoing provisions, Kuda may require you to repay the Outstanding Balance in full or any part thereof on demand by issuing a repayment notice of at least 7 (seven) business days.</span>
        </span>),
      index: 3
    },
    {
      heading: "Interest",
      list: (
        <span className="flex flex-column"><span>Kuda will charge you interest on a daily basis at the rate of 0.3% of the Overdraft Facility during the Tenor. This interest will, however, only be applicable and begin to accrue if you do not pay back the Overdraft Facility to Kuda by midnight on the Disbursement Date.
        </span><br />
          <span className="mb-4 flex">Interest will be paid by you, together with the Overdraft Facility as stated in clause 2.1 above during the Tenor until full repayment of the Outstanding Balance.</span>
        </span>),
      index: 4
    },
    {
      heading: "Representations and Warranties",
      list: (
        <span className="flex flex-column"><span>Kuda represents and warrants that it is duly incorporated and licensed under Nigerian law and has taken all necessary actions to authorise its entry into this Offer Letter.
        </span><br />
          <span className="mb-4 flex">You represent and warrant that:</span>
          <span className="mb-4 flex">a. you are not in default under any obligation in respect of any other loan that the acceptance of this Overdraft Facility will be or result in breach of or default under any provision of any other agreement to which you are a party;</span>
          <span className="mb-4 flex">b. the information/documents you have provided to Kuda, via email or other electronic medium or in other form of writing as well as documents submitted in support of the Application Form for the Overdraft Facility is true, accurate and complete;</span>
          <span className="mb-4 flex">c. you are in paid employment or have other verifiable means of repayment of the Overdraft Facility and the Interest and that should there at any relevant time (before or after the approval and draw down of the Overdraft Facility) be a change in the your employment status, you shall notify Kuda immediately and without delay of such change.</span>
        </span>),
      index: 5
    },
    {
      heading: "Event of Default",
      list:  (
        <span className="flex flex-column"><span>The occurrence of any of the following events shall cause all outstanding amounts under this Offer Letter to become immediately due and payable:
        </span><br />
          <span className="mb-4 flex">a. where you fail to make any payment on the Outstanding Balance prior to the Maturity Date;</span>
          <span className="mb-4 flex">b. where you fail to make full repayment of the Outstanding Balance prior to the end of the Tenor;</span>
          <span className="mb-4 flex">c. where you breach any of the terms and conditions of this Offer Letter including any representation or confirmation given by you in this Offer Letter;</span>
          <span className="mb-4 flex">d. where a bankruptcy petition is filed against you;</span>
          <span className="mb-4 flex">e. where fraud is detected at any time during the lifespan of this Offer Letter;</span>
          <span className="mb-4 flex">f. where you are not able to pay any other party within the meaning of Section 1 of the Bankruptcy Act (Cap 30) Laws of the Federation of Nigeria;</span>
          <span className="mb-4 flex">g. where a situation arises, which in Kuda’s opinion makes it inappropriate to continue to extend this Overdraft Facility to you;</span>
          <span className="mb-4 flex">h. where you default in the performance or observance of any other term, condition or covenant herein and such breach or default shall continue unremedied after ten (10) days’ notice having been given to you</span>
          <span className="mb-4 flex">If you are in default of your repayment obligations hereunder, and Kuda chooses to impose a default charge, Kuda shall, on the first day of default, notify you that a default charge would be applied to your Account after three (3) days from the date you ought to have repaid the Outstanding Balance. The default charge shall not in any event exceed 1% per month of the sum that remains outstanding.</span>
          <span className="mb-4 flex">If you do not repay the Outstanding Balance within the Tenor, Kuda may institute a legal action against you in the event of a default and all expenses incurred in respect of the recovery action (including legal fees) shall be added to the Outstanding Balance.</span>
        </span>),
      index: 6
    },
    {
      heading: "Global Standing Instruction Mandate",
      list: (
        <span className="flex flex-column"><span>You hereby authorise Kuda that, in the event that you default in repaying the Outstanding Balance pursuant to this Offer Letter, Kuda shall initiate a Global Standing Instruction (“GSI”) trigger (i.e., instruct the other banks/participating financial institutions to initiate a balance enquiry into any accounts maintained by you with them (and linked to your BVN) to recover outstanding principal amount and accrued interest only from any/all such accounts maintained by you).
        </span>
        </span>),
      index: 7
    },
    {
      heading: "Set-Off",
      list: (
        <span className="flex flex-column"><span>By signing this Offer Letter and by drawing on the Overdraft Facility, you covenant to repay as and when due. In the event that you fail to repay the Overdraft Facility and the Interest prior to the end of the Tenor, and it becomes delinquent, Kuda shall have the right to report to the CBN through the Credit Risk Management Systems (“CRMS”) or by any other means, and request the CBN exercise its regulatory power to direct all banks and other financial institutions under its regulatory purview to set-off your indebtedness from any money standing to your credit in any bank account and from any other financial assets they may be holding for your benefit.
        </span><br />
          <span className="mb-4 flex">You covenant and warrant that Kuda shall have power to set-off your indebtedness under this Offer Letter from all such monies and funds standing to your credit/benefit in any and all such accounts or from any other financial assets belonging to you in the custody of any such bank.</span>
          <span className="mb-4 flex">You hereby waive any right of confidentiality whether arising under common law or statute or in any other manner whatsoever and irrevocably agree that you shall not argue to the contrary before any court of law, tribunal administrative authority or any other body acting in any judicial or quasi-judicial capacity.</span>
        </span>),
      index: 8
    },
    {
      heading: "Miscellaneous Variations to this Offer Letter",
      list:(
        <span className="flex flex-column"><span>Kuda can change, add to, delete or replace the terms of this Offer Letter at any time and will notify you about those changes after which they shall become effective.
        </span><br />
          <span className="mb-4 flex">In the event that Kuda varies the rate of interest payable on the Overdraft Facility, you shall be duly notified ten (10) days prior to the implementation of the said variation.</span>
        </span>),
      index: 9
    },
    {
      heading: "Assignment",
      list:(
        <span className="flex flex-column"><span>Kuda can change, add to, delete or replace the terms of this Offer Letter at any time and will notify you about those changes after which they shall become effective.
        </span><br />
          <span className="mb-4 flex">In the event that Kuda varies the rate of interest payable on the Overdraft Facility, you shall be duly notified ten (10) days prior to the implementation of the said variation.</span>
        </span>),
      index: 10
    },
    {
      heading: "Statements",
      list: (
        <span className="flex flex-column"><span>The status of the Overdraft Facility shall be contained in the monthly statements sent to the you in respect of your Account. Notwithstanding this, Kuda may also provide separate statements in respect of the Overdraft Facility to you
        </span>
        </span>),
      index: 11
    },
    {
      heading: "Communication",
      list: (
        <span className="flex flex-column"><span>Any communications between Kuda and you regarding the Overdraft Facility shall be via the Kuda in-app chat, email (help@kudabank.com) and telephone (01-6335832). If and where necessary, you may contact the Consumer Protection Department of the Central Bank of Nigeria by writing to the Director, Consumer Protection Department, CBN, Abuja or send an email to: cpd@cbn.gov.ng.
        </span>
        </span>),
      index: 12
    },
    {
      heading: "Data Privacy and Disclosure of Information",
      list: (
        <span className="flex flex-column"><span>You hereby authorise us to collect, save and process your information (personal or otherwise) for the purpose of the Overdraft Facility.
        </span><br />
          <span className="mb-4 flex">You further authorise us and consent to our transfer or disclosure of any information or documentation relating to the Overdraft Facility to third parties that would require the information for the purpose of confirming your eligibility to receive the Overdraft Facility including but not limited to credit reference agencies, collection agencies, creditors and law enforcement agencies.</span>
          <span className="mb-4 flex">You also authorise us to contact your friends, employer, relatives, or neighbours for information relating to your employment status, your telephone number or address, for any purpose connected with the Overdraft Facility provided to you.</span>
        </span>),
      index: 13
    },
    {
      heading: "Governing Law and Jurisdiction",
      list: (
        <span className="flex flex-column"><span>This Offer Letter shall be governed by and construed in accordance with the laws of Nigeria. The courts of Nigeria shall have exclusive jurisdiction in any matter arising from it.
        </span>
        </span>),
      index: 14
    },
    {
      heading: "MEMORANDUM OF ACCEPTANCE BY CUSTOMER",
      list: (
        <span className="flex flex-column"><span>I hereby accept the terms and conditions contained in this Offer Letter for the Overdraft Facility from Kuda Microfinance Bank Limited. I have read and understood the provisions of this Offer Letter and by choosing to proceed, I agree to be bound by the terms and conditions contained herein.
        </span>
        </span>),
      index: 15
    },
  ]

  useEffect(() => {
    import("../../../../../firebase").then(firebase => {
      getdata(firebase)
    })
  }, [])

  useEffect(() => {
    if (localTerms) {
      setQuestions(JSON.parse(localTerms))
    } else {
      setQuestions(localOverdraft)
    }
  }, [localTerms])

  return (
    <Fragment>
      <div
        className="kuda-section--100"
        onClick={() => {
          setId("wHzrrWoIxlc")
          showModal()
        }}
      >
        <div className="kuda-borrow">
          <Img
            alt="kuda Microfinance Overdraft"
            src={BorrowImage}
            loader={<About1 className="blur kuda-ambassador-single" />}
            className="kuda-ambassador-single"
          />
          <div className="kuda-play">
            <PlayPrimary />
          </div>
        </div>
      </div>

      <EntryFold
        title={entryContent.title}
        subtitle={entryContent.subtitle}
        illustration={entryContent.illustration}
        isImage={true}
      />
      <BelowFoldCards
        topFeatures={kudaTopFeatures}
        heading={"How overdrafts work"}
        className="no-top--padding bottom--spacing--lg"
      />
      <div className="kuda-inner--heading text-center">
        <div className="kuda-section--inner">
          <div className="kuda-section--100">
            <h1 className="kuda-section--heading text-center text-xlbold color-primary bottom--spacing--md">
              How to borrow
            </h1>
          </div>
        </div>
      </div>

      <div
        className="kuda-section--100"
        onClick={() => {
          setId("R10Y8QHde6Q")
          showModal()
        }}
      >
        <div className="kuda-borrow">
          <Img
            alt="kuda Microfinance Overdraft"
            src={HowBorrowImage}
            loader={
              <About2 className="blur kuda-ambassador-single borrow-has--border" />
            }
            className="kuda-ambassador-single borrow-has--border"
          />
          <div className="kuda-play">
            <Play />
          </div>
        </div>
      </div>

      <div className="kuda-section--100 kuda-spacing--inner kuda-standard--section no-top--padding">
        <h1 className="kuda-section--heading text-center text-xlbold color-primary title-bottom--spacing">
          Overdraft FAQs
        </h1>
        <div className="accordion mt-5" role="tablist">
          {countries.map(({ heading, list }, index) => (
            <Panel
              key={index}
              activeTab={activeTab}
              index={index}
              q={heading}
              a={list}
              activateTab={() => activateTab(index)}
            />
          ))}
        </div>
      </div>
      <CTA />
    </Fragment>
  )
}

export default Borrow
