import React, { useState } from "react"
import BorrowPage from "../../components/body/pages/en-ng/borrow/index"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ModalVideo from "react-modal-video"
import { Fragment } from "react"

const Borrow = () => {
  const [isOpen, setOpen] = useState(false)
  const [id, setId] = useState("")

  const showModal = () => {
    setOpen(true)
  }

  return (
    <Fragment>
      <Layout>
        <SEO
          canonical={"https://kuda.com/en-ng/borrow/"}
          title="Overdrafts from Kuda | The Money App For Africans"
          description="Kuda Overdrafts are short-term loans you can take any time as long as you use your Kuda account regularly. No paperwork, just 0.3% daily interest."
        />
        <BorrowPage showModal={showModal} setId={setId} />
      </Layout>
      <ModalVideo
        channel="youtube"
        autoplay
        modestbranding={1}
        controls={0}
        isOpen={isOpen}
        videoId={id}
        onClose={() => setOpen(false)}
      />
    </Fragment>
  )
}

export default Borrow
